 //_components/loaders.scss 

.la-lorder1-component{
   display: flex;
   align-items: center;
   justify-content: center;
}

.lorder-green .la-lorder1 .loading1-spinner-circle .loading1-spinner-circle-inner::after{
   background-color: #7ed321;
}

 .la-lorder1{
    display: inline-block;
    position: relative;

    .loading1-img{
        width: 50px;
        height: 50px;
        animation: loading1-img infinite 6s;
        animation-timing-function: cubic-bezier(0.6, -0.03, 0.32, 1.01);
     }




     .loading1-bg{
        width: 50px;
        height: 50px;
      //  background-color: red;
        border-radius: 100%;
        position: absolute;
        display: block;
        top: 2px;
        left: 3px;
        scale: 1;
        transition: 6s;
        animation: loading1-bg infinite 6s;
        animation-timing-function: cubic-bezier(0.6, -0.03, 0.32, 1.01);
     }

     .loading1-spinner{
      position: absolute;
      height: 70px;
      width: 70px;
      top: -10px;
      scale: 0.9;
      text-align: center;
      left: -10px;
     }


     .loading1-spinner-circle{
        position: absolute;
        height: 35px;
        margin: auto;
        width: 5px;
        display: inline-block;
        transform: translateX(-50%);


        .loading1-spinner-circle-inner{
            height: 35px;
            width: 5px;
            display: inline-block;
            transform-origin: bottom;
            animation: loading1-spinner 3s infinite;
            transition: 5s;
            position: relative;


            &::after{
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                transform: translateX(-50%);
                background-color: rgb(0, 0, 0);
                content: ' ';
                width: 7px;
                height: 7px;
                border-radius: 100px;
            }

        }



        @for $i from 1 through 10 {

         &:nth-child(#{$i}){
            .loading1-spinner-circle-inner{
                  opacity: (1 / 100) * (100 - ($i * 15));
                  animation-delay: (70ms * $i) + ($i * 30) ;
            }    
        }


       }
       
        

       


     }

 }


 @keyframes loading1-img {
    0%{transform: rotateY(0deg);}
    25%{transform: rotateY(90deg);}
    50%{transform: rotateY(180deg);}
    75%{transform: rotateY(270deg);}
    100%{transform: rotateY(360deg);}
 }



 
 @keyframes loading1-spinner {
   0%{transform: rotate(0deg);}
   100%{transform: rotate(360deg);}
}


 


 @keyframes loading1-bg {
    0%{
        
        transform: rotateY(0deg) scale(0);}
    25%{transform: rotateY(90deg);}
    50%{transform: rotateY(180deg) scale(0);;}
    75%{transform: rotateY(270deg);}
    100%{transform: rotateY(360deg) scale(0);;}
 }
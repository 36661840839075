/* Default content for titles.scss */ 

.la-title-1{
    font-family: $font-style-1;
    font-size: $font-lg;
    font-weight: 600;
}

.la-title-2{
    font-size: $font-xxl;
    font-weight: bold;
    font-family: $font-style-1;
}

.la-title-3{
    font-size: $font-md;
    font-weight: bold;
    font-family: $font-style-1;
}



.la-title-opacity-50{
    opacity: 50%;
}


.bold{
    font-weight: bold;
}
/* Default content for display.scss */ 

.la-flex{
    display: flex;
}

.la-block{
    display: block;
}

.la-inline-block{
    display: inline-block;
}

.flex-1{
    flex : 1;
}

.w-50{
    width: 50%;
}



.w-100{
    width: 100%;
}


.flex-fixed{
    flex: 0 0 auto;
}


.align-items-center {align-items: center;}
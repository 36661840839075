.single-restaurent-points-container{

    margin-top: 20px;
    max-width: 380px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;

    .recharge-container{
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.11); 
        padding: 10px;
        border-radius: 10px;

        .points-icon{
            display: flex;
            align-items: center;
            padding-left: 10px;
        }
        .points-content{
            flex: 1;
            padding-left: 15px;
            .top{
                font-family: $font-style-2;
                color: #fff;
                opacity: 0.5;
                font-size: 12px;
            }

            .bottom{
                font-family: $font-style-1;
                color: #fff;
                font-size: 16px;
                font-weight: 700;
            }

        }
        .points-end{
            display: flex;
            align-items: center;
            .point-button{
                background-color: #3ACE94;
                padding: 10px;
                color: #003F27;
                font-size: 12px;
                font-weight: 700;
                border-radius: 5px;
                display: flex;
                align-items: center;
                transition: 0.3s;
                &:focus,&:active{
                    opacity: 0.5;
                }
            }
        }

    }



    .recharge-container.without-recharge{
      
        .points-content{
            .top{
                font-family: $font-style-2;
                color: #fff;
                opacity: 0.5;
                font-size: 14px;
                font-weight: 700;
            }


        }
        .points-end{
            .bottom{
                font-family: $font-style-1;
                color: #fff;
                font-size: 16px;
                padding-right: 5px;
                font-weight: 700;
            }
        }

    }


}
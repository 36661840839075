/* Default content for new_checkout.scss */ 
.checkout-container{
    .select-option-cust {
        margin: 10px 0px;
        font-family: 'exo';
        font-size: 16px;
        font-weight: 600;
    
        &.selected-sus{
            .unselected-icon{
                display: none;
            }
            .selected-icon{
                display: inline-block;
            }
        }

        .unselected-icon{
            display: inline-block;
        }

        .selected-icon{
            display: none;
        }

        .unselected-icon {
            float: right;
            width: 20px;
            height: 20px;
            background-color: gray;
            border-radius: 50%;
        }
    
        .selected-icon {
            float: right;
            font-size: 22px;
        }
    }
    
    .selected-cus {
        background: #00000014;
        padding: 5px 10px;
        border-radius: 5px;
        margin-left: -10px;
        margin-right: -10px;
        scale: 1.1;
    }
    
    .payment-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .field-error {
        text-align: right;
        font-size: 14px;
        color: red;
    }
    
    .address-change-warning {
        background-color: #fbcb37;
        border-radius: 5px;
        text-align: center;
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 5px;
        margin-top: 5px;
        font-weight: 600;
        color: #4f4f4f;
        margin-top: 10px;
    
        ion-icon {
            font-size: 36px;
        }
    }
    
    .not-available-error {
        background-color: #f13e3e;
        min-height: 50px;
        border-radius: 5px;
        color: white;
        padding: 10px;
        font-size: 14px;
        margin: 10px;
    
        .err-title {
            text-align: center;
        }
    
        .unavailable-menus {
            margin-top: 5px;
            border-top: 1px dashed white;
            padding-top: 5px;
    
            div {
                padding: 0px 10px;
    
                .time {
                    float: right;
                }
            }
        }
    }
    
    .sub {
        display: flex;
        margin-top: 15px;
    }
    
    .ml15 {
        margin-left: 15px;
    }
    
    .show-in-cart {
        text-align: center;
        padding-top: 10px;
    
        span {
            background: white;
            color: red;
            padding: 2px 10px;
            border-radius: 12px;
        }
    }
    
    .grayed {
        color: rgb(77, 76, 76);
    }
    
    .change-delivery-location {
        text-align: right;
        
        span {
            background: red;
            padding: 2px 10px;
            border-radius: 5px;
            font-size: 14px;
            color: white;
        }
    }
}
 //_components/radio_select_group.scss 


 .radio-select-group{

  background-color: $default-input-background;
  border: 2px solid $default-input-background;
  border-radius: 10px;
  min-height: 50px;


  &.radio-selected{
      border: 2px solid $sucess-color;
  }


  .radio-icon{
      display: flex;
      justify-content: center;
      align-items: center;
  }


  .radio-group-white-bg{
      background-color: #fff;
      border-radius: 5px;
      padding: 10px 10px;
  }

  .container {
      display: block;
      position: relative;
      margin-bottom: 0;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      user-select: none;
      width: 25px;
      height: 25px;
      padding-bottom: 0;
      padding-top: 0;
    }
    
    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    
    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
      border-radius: 50%;
    }
    
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }
    
    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: $sucess-color;
      border: 2px solid $default-input-background;
      box-shadow: 0px 0px 0px 2px $sucess-color;
    }
    
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }
    

    .checkmark-inside{
      text-align: center;
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }






}





 .radio-select-group-2{

  scale: 0.9;

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #00000026;
    border-radius: 50%;
    border: 1px solid #ffffff85
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: $sucess-color;
      border: 2px solid $default-input-background;
      box-shadow: 0px 0px 0px 2px $sucess-color;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }


  .checkmark-inside{
    display: none;
  }

  .container input:checked ~ .checkmark .checkmark-inside{
    display: flex;
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
  }

  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    
  }


}
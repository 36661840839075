 //_components/inputs.scss 

 .la-input-2{
    height: 38px;
    border-radius: 10px;
    background-color: $default-input-background;
    color: $default-link-color;
    border: 1px solid $default-input-background;
    padding: 5px 10px;
    width: 100%;
   font-family: $font-style-2;

    &::placeholder {   
      background-color: $default-input-background;
      opacity: 1; /* Firefox */
      color: $default-input-text;
    
   }

      &[type=password]::-ms-reveal,
      &[type=password]::-ms-clear
      {
         display: none;
      }


 }


.la-input-error{
   .la-input-2{
      outline: 2px solid $error-text-color;
   }
}
 //_components/buttons.scss 


 .la-button{


    font-family: $font-style-1;
    text-decoration: none;


    &.button-xs{
        padding: 5px 10px;
        border-radius: 3px;
        font-weight: 600;
        font-size: $font-sm;
    }

    &.btn-sm{
        padding: 6px 30px;
        border-radius: 5px;
        font-weight: 600;
        font-size: $font-md;
    }

    &.default{
        background-color: $default-color;
        color: $default-link-color;
        font-family: $font-style-1;
        font-weight: 700;
    }


    &.sucesss{
        background-color: $sucess-color;
        color: $sucess-link-color;
    }
    
    &.warning{
        background-color: $warning-color;
        color: $warning-link-color;
    }

 }
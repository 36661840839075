@media (min-width: 767.98px) {


    
 .select-modal-container-parent{


    .select-modal-container{


        .select-modal-content{


            
            border: $border;
            border-bottom: $border;


            &::-webkit-scrollbar {
                width: 5px;
               // height: 0;
               border-radius: 100%;
            }

            /* Track */
            &::-webkit-scrollbar-track {
            background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
            background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
            background: #555;
            }

        }

    }

 }


 } 



 .select-modal-container-parent .select-modal-container {
      //  overflow: hidden;
        border-radius: 20px;
}
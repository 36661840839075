 //_components/alerts.scss 


 .la-alert{
    font-family: $font-style-2;
    font-size: 12px;
    background-color: $error-bg-color;
    color: $error-text-color;
    font-weight: 500;
    padding: 10px;
    border-radius: 5px;


    &.notice{
      background-color: $notice-bg-color;
      color: $notice-text-color;
    }


 }
/* Default content for border.scss */ 
$increment: 5;
$max-value: 50; // Adjust this as needed

@for $i from 0 through $max-value / $increment {
  $value: $i * $increment;

  // Border-radius classes for variable values
  .br-tl-#{$value} {
    border-top-left-radius: #{$value}px;
  }
  .br-tr-#{$value} {
    border-top-right-radius: #{$value}px;
  }
  .br-br-#{$value} {
    border-bottom-right-radius: #{$value}px;
  }
  .br-bl-#{$value} {
    border-bottom-left-radius: #{$value}px;
  }
  .br-x-#{$value} {
    border-top-left-radius: #{$value}px;
    border-top-right-radius: #{$value}px;
    border-bottom-right-radius: #{$value}px;
    border-bottom-left-radius: #{$value}px;
  }
  .br-y-#{$value} {
    border-top-left-radius: #{$value}px;
    border-top-right-radius: #{$value}px;
    border-bottom-right-radius: #{$value}px;
    border-bottom-left-radius: #{$value}px;
  }

  // Border-radius class for a fixed value of 10px
  .br-#{$value} {
    border-radius: #{$value}px;
  }
  
}


.b-b-default{
  border-bottom: 1px solid $border-color-2;
}


.b-t-default{
  border-top: 1px solid $border-color-2;
}
/* 
Theme name: Gastro App GMLA1 Theme 1.0
Author: PraLa
Created on: 27/09/2023
Last updated: 27/09/2023
Version: 1.0.0
Description: This theme is a complete overhaul of the previous theme, which had messy and unnecessary codes.
             This theme aims to create a new clean and elegant design for the project, with proper management and version control. 
             It uses the latest scss features and best practices to ensure a high-quality and maintainable code. 
             It also applies the theme changes part by part, instead of all at once, to avoid breaking the existing functionality
             and layout of the project.
*/


@import "settings.scss"; 
@import "global.scss"; 
@import "profile/_main.scss"; 
@import "titles.scss"; 
@import "margins.scss"; 
@import "components/inputgroup/_main.scss"; 
@import "typography.scss"; 
@import "components/buttons/_main.scss"; 
@import "components/alerts/_main.scss"; 
@import "components/forms/_main.scss"; 
@import "display.scss"; 
@import "border.scss"; 
@import "tabresponsiveerror.scss"; 
@import "components/loaders/_main.scss"; 
@import "order_summery/_main.scss"; 
@import "checkout/_main.scss"; 
@import "components/radio_buttons/_main.scss"; 
@import "components/radio_select_group/_main.scss"; 
@import "colors.scss"; 
@import "components/icons/_main.scss"; 
@import "components/inputs/_main.scss"; 
@import "login/_main.scss"; 
@import "components/selectModal/_main.scss"; 
@import "animation.scss"; 
@import "animations/button/_main.scss"; 
@import "pointHistory/_main.scss"; 
@import "new_checkout.scss"; 
@import "home/_main.scss"; 
